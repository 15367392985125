import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Box, Button, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

import { fetchKYCDocumentsAPI, updateKYCDocumentsAPI } from "../api";
import { RootState } from "../store";
import { RequiredFile, setLoading } from "../store/slices/kycSlice";
import { DynamicObject, FilesToUpload } from "../types";
import { capitalizeAndConcatenate } from "../utils";
import FileInput from "./FileInput";
import CircularProgressLoader from "./Loader";

const SubmittedComponent = () => {
  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "80%",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
      }}
    >
      <Typography sx={{ fontSize: "24px" }}>
        Your documents have been submitted.
      </Typography>
      <CheckBoxIcon sx={{ color: "#2ac579", fontSize: "32px" }} />
    </Box>
  );
};

const AlreadySubmittedComponent = () => {
  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "80%",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
      }}
    >
      <Typography sx={{ fontSize: "24px" }}>
        Your documents have been submitted already and are under review.
      </Typography>
      <CheckBoxIcon sx={{ color: "#2ac579", fontSize: "32px" }} />
    </Box>
  );
};

const KycForm = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [filesToUpload, setFilesToUpload] = useState<FilesToUpload[]>([]);
  const [initialValues, setInitialValues] = useState<DynamicObject>({
    documentNumber: "",
  });
  const [validationSchema, setValidationSchema] = useState({});

  const { accountId, authKey, loading } = useSelector(
    (state: RootState) => state.kyc
  );

  const onSubmit = async (values: any) => {
    try {
      setSubmitting(true);
      const promises = filesToUpload.map((obj) =>
        updateKYCDocumentsAPI(authKey, params?.embPartner ?? "", {
          document_type: (obj.type ?? "").toUpperCase(),
          document_content: values[obj.name],
        })
      );

      await Promise.all(promises);
      setSubmitted(true);
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  const fetchDocs = async () => {
    try {
      dispatch(setLoading(true));
      let res = await fetchKYCDocumentsAPI(authKey, accountId);
      if (res?.status === 200 && res?.data?.documents?.length) {
        let files: FilesToUpload[] = [];
        let init: DynamicObject = { documentNumber: "" };
        let schema: any = {
          // documentNumber: Yup.string().required("Required*"),
        };
        res.data.documents.forEach((doc: RequiredFile, index: number) => {
          if (
            doc.status?.toLowerCase() === "pending" ||
            doc.status?.toLowerCase() === "pending_documentation"
          ) {
            let obj: FilesToUpload = {
              id: "",
              name: "",
              label: "",
              type: "",
            };
            obj["id"] = `file${index}`;
            obj["name"] = `file${index}`;
            obj["label"] = capitalizeAndConcatenate(doc.type);
            obj["type"] = doc.type;
            files.push(obj);
            schema[`file${index}`] = Yup.mixed().required("Required*");
            init[`file${index}`] = null;
          }
        });
        setFilesToUpload(files);
        setInitialValues(init);
        const tempvalidationSchema = Yup.object().shape(schema);
        setValidationSchema(tempvalidationSchema);
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (accountId) {
      fetchDocs();
    }
  }, []);

  if (loading) return <CircularProgressLoader />;

  if (submitted) return <SubmittedComponent />;

  if (!filesToUpload?.length && !loading) return <AlreadySubmittedComponent />;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          p: "30px",
          borderRadius: "10px",
          boxShadow: " rgba(0, 0, 0, 0.25) 0px 25px 50px -12px",
          width: "400px",
          background: "white",
          mt: "30px",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "32px",
            fontWeight: 500,
            mb: "10px",
          }}
        >
          KYC Form
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "16px",
            fontWeight: 400,
            mb: "30px",
          }}
        >
          Please fill all the fields given below.
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={
            Object.entries(validationSchema)?.length ? validationSchema : null
          }
          onSubmit={onSubmit}
          isInitialValid={false}
        >
          {({ errors, touched, setFieldValue, setFieldError, isValid }) => {
            return (
              <Form>
                {filesToUpload.map((file) => {
                  return (
                    <FileInput
                      disabled={submitting}
                      key={file.id}
                      name={file.name}
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      label={file.label}
                    />
                  );
                })}
                <Button
                  sx={{ textTransform: "none", mt: "20px" }}
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={!isValid || submitting}
                >
                  {submitting ? <CircularProgressLoader size={24} /> : "Submit"}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
};

export default KycForm;
