import axios, { AxiosInstance } from "axios";

export function createAxiosInstance(authKey: string): AxiosInstance {
  const instance = axios.create();

  instance.interceptors.request.use((config) => {
    config.headers.set("X-API-KEY", process.env.REACT_APP_ADMIN_API_KEY);
    config.headers.set("X-AUTH-KEY", authKey);
    config.headers.set("dlocal_env", process.env.REACT_APP_KYC_DLOCAL_ENV);

    return config;
  });

  instance.defaults.baseURL = process.env.REACT_APP_ADMIN_SERVER_URL;
  return instance;
}

export function capitalizeAndConcatenate(str: string): string {
  if (!str || typeof str !== "string") {
    throw new Error("Input must be a non-empty string");
  }

  const words: string[] = str.split("_");

  const capitalizedWords: string[] = words.map((word: string) => {
    if (word.trim() === "") {
      return "";
    }

    const firstLetter: string = word[0].toUpperCase();
    const restOfWord: string = word.slice(1);
    let finalWord = firstLetter + restOfWord;
    if (finalWord === "Front" || finalWord === "Back") {
      finalWord = `(${finalWord})`;
    }
    return finalWord;
  });

  return capitalizedWords.join(" ");
}

export const encodeFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result?.toString()?.split(",")[1] || "";
      resolve(base64String);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};
