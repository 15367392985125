import { createAxiosInstance } from "../utils";

export async function fetchAccountIdAPI(
  authKey: string,
  embPartner: string
): Promise<any> {
  const QuoteAPI = createAxiosInstance(authKey);
  return await QuoteAPI.get(`/admin/accounts/${embPartner}`);
}

export async function fetchKYCDocumentsAPI(
  authKey: string,
  accountId: string
): Promise<any> {
  const QuoteAPI = createAxiosInstance(authKey);
  return await QuoteAPI.get(`admin/accounts/${accountId}/kyc/documents`);
}

export function updateKYCDocumentsAPI(
  authKey: string,
  embPartner: string,
  payload: { document_type: string; document_content: string }
): Promise<any> {
  const QuoteAPI = createAxiosInstance(authKey);
  return QuoteAPI.post(`admin/accounts/${embPartner}/kyc/documents`, payload);
}
