import { Box, Typography } from "@mui/material";

interface RouteNotFoundProps {}

const RouteNotFound: React.FunctionComponent<RouteNotFoundProps> = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: "100px",
      }}
    >
      <Typography sx={{ fontSize: "30px" }}>404 error</Typography>
      <Typography sx={{ fontSize: "20px" }}>This page doesn't exist</Typography>
    </Box>
  );
};

export default RouteNotFound;
