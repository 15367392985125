import CheckBoxIcon from "@mui/icons-material/CheckBox";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Box, InputLabel, Typography } from "@mui/material";
import { ErrorMessage } from "formik";
import { useState } from "react";
import { encodeFileToBase64 } from "../utils";
const FileInput = ({
  label,
  name,
  disabled,
  setFieldValue,
  setFieldError,
}: {
  label: string;
  name: string;
  disabled?: boolean;
  setFieldValue: (fieldName: string, file: string) => void;
  setFieldError: (fieldName: string, error: string) => void;
}) => {
  const [fileName, setFileName] = useState("");
  const InputLabelStyle = {
    fontWeight: 500,
    fontSize: "18px",
    color: "text.secondary",
    mt: 2,
    mb: 1,
    display: "flex",
    alignItems: "center",
  };
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const fileList = event.target.files;
    if (fileList && fileList.length > 0) {
      const file = fileList[0];
      if (file.size > 5242880) {
        // File size exceeds the limit (5MB)
        setFieldError(name, "File size exceeds the limit");
        return;
      }
      const base64String = await encodeFileToBase64(file);
      if (typeof base64String === "string") setFieldValue(name, base64String);
      setFileName(file?.name);
    }
  };
  return (
    <Box
      sx={{
        mb: "15px",
      }}
    >
      <InputLabel sx={InputLabelStyle}>
        {label}
        {fileName && <CheckBoxIcon sx={{ color: "#2ac579", ml: "10px" }} />}
      </InputLabel>
      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <label htmlFor={name} className="custom-file-upload">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                mr: "5px",
              }}
            >
              Upload
            </Typography>
            <UploadFileIcon />
          </Box>

          <input
            id={name}
            disabled={disabled}
            type="file"
            accept=".pdf,.jpg,.png"
            style={{
              display: "none",
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleFileChange(event)
            }
          />
        </label>
        <Typography
          sx={{
            color: "primary.main",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {fileName}
        </Typography>
      </Box>
      <ErrorMessage name={name} component="div" className="custom-error" />
    </Box>
  );
};

export default FileInput;
