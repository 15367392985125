import React, { useEffect } from "react";
import { useNavigate } from "react-router";

const ProtectedRoute = ({
  ProtectedComponent,
}: {
  ProtectedComponent: React.FC;
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    if (!isLoggedIn) {
      navigate("/");
    }
  }, []);
  return <ProtectedComponent />;
};

export default ProtectedRoute;
