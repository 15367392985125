import { createTheme } from "@mui/material";

const muiTheme = {
  typography: {
    fontFamily: "Poppins",
  },
  palette: {
    primary: {
      main: "#6484EC",
    },
    text: {
      primary: "#6484EC",
      secondary: "#212121",
      tertiary: "#757575",
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: "#212121",
        },
      },
    },
  },
};

const theme = createTheme(muiTheme);

export default theme;
