import { Box, ThemeProvider } from "@mui/material";
import { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import KycForm from "./components/Form";
import Login from "./components/Login";
import Navbar from "./components/Navbar";
import ProtectedRoute from "./components/ProtectedRoute";
import RouteNotFound from "./components/RouteNotFound";
import store from "./store";
import theme from "./theme/theme";

import "./App.css";

function App() {
  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("isLoggedIn");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Box
          sx={{
            background: "#F5F5F5",
            height: "100vh",
          }}
          className="App"
        >
          <Navbar />
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<RouteNotFound />}></Route>
              <Route path="/:embPartner" element={<Login />}></Route>
              <Route
                path="/:embPartner/form"
                element={<ProtectedRoute ProtectedComponent={KycForm} />}
              ></Route>
            </Routes>
          </BrowserRouter>
        </Box>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
