import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface RequiredFile {
  type: string;
  status: string;
}

const kycSlice = createSlice({
  name: "kyc",
  initialState: {
    authKey: "",
    accountId: "",
    loading: false,
  },
  reducers: {
    setAuthKeyInStore: (state, action: PayloadAction<string>) => {
      state.authKey = action.payload;
    },
    setAccountId: (state, action: PayloadAction<string>) => {
      state.accountId = action.payload;
    },

    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const { setAuthKeyInStore, setAccountId, setLoading } = kycSlice.actions;
export default kycSlice.reducer;
