import { AppBar, Toolbar } from "@mui/material";
import StereLogo from "../assets/images/StereLogo";
const Navbar = () => {
  return (
    <AppBar sx={{ p: "20px" }} position="static">
      <Toolbar>
        <StereLogo />
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
