import { Box, Button, InputLabel, TextField, Typography } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useParams, useSearchParams } from "react-router-dom";

import { fetchAccountIdAPI } from "../api";
import { RootState } from "../store";
import {
  setAccountId,
  setAuthKeyInStore,
  setLoading,
} from "../store/slices/kycSlice";
import CircularProgressLoader from "./Loader";

const Login = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loading } = useSelector((state: RootState) => state.kyc);
  const navigate = useNavigate();

  const [authKey, setAuthKey] = useState(searchParams.get("auth_key") ?? "");
  const [error, setError] = useState<string>("");

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setError("");
    setAuthKey(e?.target?.value);
    setSearchParams({ auth_key: e?.target?.value });
  };

  const onSubmit = async () => {
    try {
      setError("");
      dispatch(setLoading(true));
      if (authKey) {
        dispatch(setAuthKeyInStore(authKey));
        const res = await fetchAccountIdAPI(authKey, params?.embPartner ?? ""); // TODO : emb partner name will be dynamic
        if (res?.data?.success && res?.data?.results) {
          dispatch(
            setAccountId(
              process.env.REACT_APP_KYC_DLOCAL_ENV === "sandbox"
                ? res.data.results.sandbox_id
                : res.data.results.production_id
            )
          );
          localStorage.setItem("isLoggedIn", "true");
          navigate(`/${params?.embPartner ?? ""}/form`);
        }
      }
    } catch (err: any) {
      if (err?.response.status === 422) {
        setError(`"${params?.embPartner}" is not a valid partner !`);
      } else {
        setError(`Please provide a valid "auth_key" !`);
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  const InputLabelStyle = {
    fontWeight: 500,
    fontSize: "18px",
    color: "text.secondary",
    mt: 2,
    mb: 1,
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          p: "30px",
          borderRadius: "10px",
          boxShadow: " rgba(0, 0, 0, 0.25) 0px 25px 50px -12px",
          width: "400px",
          background: "white",
          mt: "30px",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "32px",
            fontWeight: 500,
            mb: "10px",
          }}
        >
          Login
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "16px",
            fontWeight: 400,
            mb: "30px",
          }}
        >
          Please enter your Authentication Key.
        </Typography>
        <InputLabel sx={InputLabelStyle}>Auth key</InputLabel>
        <TextField
          disabled={loading}
          value={authKey}
          onChange={onChange}
          fullWidth
        />
        <Typography sx={{ color: "red" }}>{error}</Typography>
        <Button
          sx={{ textTransform: "none", mt: "30px" }}
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          onClick={onSubmit}
          disabled={!authKey}
        >
          {loading ? <CircularProgressLoader size={24} /> : "Submit"}
        </Button>
      </Box>
    </Box>
  );
};

export default Login;
