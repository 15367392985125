import { combineReducers, configureStore } from "@reduxjs/toolkit";
import kycSlice from "./slices/kycSlice";

const rootReducer = combineReducers({
  kyc: kycSlice,
});

const store = configureStore({
  reducer: rootReducer,
});
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export default store;
